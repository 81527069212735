import React from "react"
import { graphql } from "gatsby"
import {
  AiOutlineMail,
  AiOutlineLinkedin,
  AiOutlineInstagram,
  AiOutlinePhone,
} from "react-icons/ai"
import { RiMapPinLine } from "react-icons/ri"
import { Heading, Text, Icon, Flex } from "@chakra-ui/react"
import PageLayout from "../components/PageLayout/PageLayout"
import SEO from "../components/seo/Seo"

export default function Contact({ data }) {
  const { email, phone, instagram, linkedin, location } = data.prismicContact.data

  return (
    <PageLayout>
      <SEO
        title="Contato"
        description={`Entre em contato conosco através do email ${email}, ou das nossas redes sociais ${instagram}`}
      />
      <Heading mb="0.8rem" fontSize="2rem">
        Contato
      </Heading>
      {email ? (
        <Flex alignItems="center">
          <Icon as={AiOutlineMail} boxSize="2rem" mr="1rem" />
          <Text>{email}</Text>
        </Flex>
      ) : null}
      {phone ? (
        <Flex alignItems="center">
          <Icon as={AiOutlinePhone} boxSize="2rem" mr="1rem" />
          <Text>{phone}</Text>
        </Flex>
      ) : null}
      {instagram ? (
        <Flex alignItems="center">
          <Icon as={AiOutlineInstagram} boxSize="2rem" mr="1rem" />
          <Text>{instagram}</Text>
        </Flex>
      ) : null}
      {linkedin ? (
        <Flex alignItems="center">
          <Icon as={AiOutlineLinkedin} boxSize="2rem" mr="1rem" />
          <Text>{linkedin}</Text>
        </Flex>
      ) : null}
      {location ? (
        <Flex alignItems="center">
          <Icon as={RiMapPinLine} boxSize="2rem" mr="1rem" />
          <Text>{location}</Text>
        </Flex>
      ) : null}
    </PageLayout>
  )
}

export const query = graphql`
  query ContactPageQuery {
    prismicContact {
      data {
        email
        phone
        instagram
        linkedin
        location
      }
    }
  }
`
